import React from 'react';

import FidelityLogo from "../../assets/fidelity_logo.webp";
import ServiceScanning from "../../assets/service_scanning.webp"
import ServiceWalkthrough from "../../assets/service_walkthrough.webp"
import ServiceTour from "../../assets/service_360.webp"

import PageMetaDefault from "../../components/page-meta-default"
import JapaneseFooter from "../../components/footer-ja"
import JapaneseHeader from "../../components/header-ja"
import LandingBannerImage from "../../assets/landing_illustration.svg";
import { Link } from "gatsby"
import { PAGES } from "../../constants"

const JapaneseHome = () => (
  (
    <>
      <PageMetaDefault />
      <JapaneseHeader />
      <div id="banner-bg">
        <div className="width-limit">
          <div id="landing-banner">
            <h1 id="landing-banner-heading">没入型Web体験を構築します</h1>
            <p id="landing-banner-text">
              インテリア設計からロボット分野まで、各種業界におけるVRアプリ活用について戦略構築と、実アプリを設計・開発いたします。<br />
              貴社におけるVR活用について、是非ご相談ください。 あなたのビジョンを実現します！
            </p>
            <Link to={PAGES.JAPANESE_CONTACT_US} className="landing-cta">
              連絡をとる
            </Link>
            <img id="landing-banner-illustration" src={LandingBannerImage} alt="" />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="width-limit">
          <h2>製品</h2>
          <div className="flex celestar-flex">
            <div className="celestar-text fidelity">
              <h3>フィデリティ スタジオ</h3>
              <p>私たちFidelity Studioは、作品や製品の実物を忠実に再現した3Dデジタルコンテンツの制作を専門とするスタジオです。日本とインドの混成チームであり、日本特有の繊細な美意識とFidelity Engineを生み出したインド開発チームの高い技術力を持ち合わせることによる、世界最高レベルのクオリティを特徴としています。</p>
              <a href="https://fidelitystudio.net" className="outline-button">もっと調べる</a>
            </div>
            <img src={FidelityLogo} className="celestar-screenshot fidelity" alt="" />
          </div>
        </div>
      </div>
      <div className="section grey-bg" id="services-section">
        <div className="width-limit">
          <h2>サービス</h2>
          <div className="flex section-flex">
            <div className="service-item">
              <p>3Dスキャン</p>
              <img src={ServiceScanning} alt="" />
            </div>
            <div className="service-item">
              <p>vr ウォークスルー</p>
              <img src={ServiceWalkthrough} alt="" />
            </div>
            <div className="service-item">
              <p>360度写真ベースのVR</p>
              <img src={ServiceTour} alt="" />
            </div>
          </div>
          <Link to="/ja/services" className="outline-button">続きを読む</Link>
        </div>
      </div>
      <JapaneseFooter />
    </>
  )
)

export default JapaneseHome;
