import React from 'react';
import {Link} from "gatsby";
import LinkedInImg from '../assets/icon_linkedin.svg';
import TwitterImg from '../assets/icon_twitter.svg';
import {
  contactEmail,
  linkedinLink,
  PAGES,
  twitterLink,
} from "../constants"

const JapaneseFooter = () => (
  <footer id="footer">
    <a href={twitterLink} className="footer-icon" target="_blank" rel="noreferrer noopener">
      <img src={TwitterImg} alt="Twitter" />
    </a>
    <a href={linkedinLink} className="footer-icon" target="_blank" rel="noreferrer noopener">
      <img src={LinkedInImg} alt="Linked In" />
    </a>
    <br />
    <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer noopener">{contactEmail}</a>&emsp;&emsp;
    <Link to={PAGES.JAPANESE_CONTACT_US}>お問い合わせ</Link>&emsp;&emsp;
    <Link to={PAGES.PRIVACY_POLICY}>プライバシー&nbsp;ポリシー</Link><br />
    &copy; 株式会社ユークリッドXRイマーシブ。東京、日本
  </footer>
);

export default JapaneseFooter;
