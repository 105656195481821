import React from 'react';
import '../index.scss';

import LogoNew from "../assets/exr_logo_compressed.webp";
import IconHamburger from "../assets/icon_menu.svg";
import IconClose from "../assets/icon_close.svg";
import {Link} from "gatsby";
import { PAGES } from "../constants"

const SCROLL_THRESHOLD = 30;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      navOpen: false,
    };
  }

  onScroll = () => {
    const { scrollY } = window;
    const { scrolled } = this.state;
    if (scrollY > SCROLL_THRESHOLD && !scrolled) {
      this.setState({
        scrolled: true,
      });
    }
    if (scrollY <= SCROLL_THRESHOLD && scrolled) {
      this.setState({
        scrolled: false,
      });
    }
  };

  setOpen = (navOpen) => {
    this.setState({
      navOpen,
    });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    this.onScroll();
    // this.forceUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, { passive: true });
  }

  render() {
    const { navOpen, scrolled } = this.state;
    return (
      <header id="header" className={scrolled ? 'scrolled' : ''}>
        <div className="width-limit flex align-center">
          <Link to={PAGES.JAPANESE}>
            <img id="header-logo" src={LogoNew} alt="Euclid logo" />
          </Link>
          <div className="flex-gap" />
          <div id="header-flex">
            <div className="language-selection">
              <Link to={PAGES.MAIN} className={`header-language`}>
                en
              </Link>
              <button className={`header-language selected small`}>
                日本語
              </button>
            </div>
            <div id="header-nav" className={navOpen ? 'open' : ''}>
              <ul className="flex align-center">
                <button id="header-close" onClick={() => this.setOpen(false)}>
                  <img
                    alt="Close"
                    width={30}
                    height={28}
                    src={IconClose}
                    id="hamburger-close-image"
                  />
                </button>
                <li><a href="https://fidelitystudio.net/">フィデリティ スタジオ</a></li>
                <li><Link to="/ja/services">サービス</Link></li>
                <li>
                  <Link to={PAGES.JAPANESE_CONTACT_US} className="landing-cta">
                    連絡をとる
                  </Link>
                </li>
              </ul>
            </div>
            <button id="header-hamburger" onClick={() => this.setOpen(!navOpen)}>
              <img alt="Menu" src={IconHamburger} id="hamburger-image" width={30} height={28} />
            </button>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
